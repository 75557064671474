// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-contact-js": () => import("D:\\a\\1\\s\\src\\components\\contact.js" /* webpackChunkName: "component---src-components-contact-js" */),
  "component---src-components-general-page-js": () => import("D:\\a\\1\\s\\src\\components\\generalPage.js" /* webpackChunkName: "component---src-components-general-page-js" */),
  "component---src-pages-index-js": () => import("D:\\a\\1\\s\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-components-stage-page-js": () => import("D:\\a\\1\\s\\src\\components\\stage-page.js" /* webpackChunkName: "component---src-components-stage-page-js" */),
  "component---src-components-about-js": () => import("D:\\a\\1\\s\\src\\components\\about.js" /* webpackChunkName: "component---src-components-about-js" */),
  "component---src-components-tools-js": () => import("D:\\a\\1\\s\\src\\components\\tools.js" /* webpackChunkName: "component---src-components-tools-js" */),
  "component---src-pages-404-js": () => import("D:\\a\\1\\s\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archive-js": () => import("D:\\a\\1\\s\\src\\pages\\archive.js" /* webpackChunkName: "component---src-pages-archive-js" */)
}

